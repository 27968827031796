import API from "./api";
import auth0 from "./auth0";
import okta from "./okta";
import getFile from "./xml";
const config = getFile("/config.json");

const api = new API();

const IDP = config.OKTA_ISSUER && config.OKTA_CLIENT_ID ? okta : auth0;

export { api, IDP };
export { default as sentry } from "./sentry";
