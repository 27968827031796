// eslint-disable-next-line no-restricted-imports
import { _ } from "svelte-i18n";
import { readable } from "svelte/store";

export default readable(null, (set) => {
  return _.subscribe((messageFormatter) => {
    set((id, options) => {
      let fixedId = id;

      if (typeof id !== "string") {
        fixedId = `${id}`; // convert to string
      }

      return messageFormatter(fixedId, options);
    });
  });
});
