import App from "./App.svelte";
import "@gl/content-manager/src/content-manager.js";
import * as Sentry from "@sentry/svelte";
import { Integrations } from "@sentry/tracing";
import { sentry } from "./services/index";

sentry.init();

const app = new App({
  target: document.getElementById("app"),
});

export default app;
