import { api } from "../services/index";

export function getUserApps(userID) {
  return api.get(`/users/${userID}/apps`);
}

export function getUserPolicies(userID) {
  return api.get(`/users/${userID}/policies`);
}

export function getUserGroups(userID) {
  return api.get(`/users/${userID}/groups`);
}

export function getUserRoles(userID) {
  return api.get(`/users/${userID}/roles`);
}

export function getUsers() {
  return api.get(`/users`);
}

export function getGroups() {
  return api.get(`/groups`);
}

export function me() {
  return api.get(`/me`);
}
