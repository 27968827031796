import { writable } from "svelte/store";

function saveLanguage() {
  let language = localStorage.getItem("language");
  if (language) language = JSON.parse(language);

  const { subscribe, set } = writable(language);

  return {
    subscribe,
    set: (language) => {
      localStorage.setItem(
        "language",
        language ? JSON.stringify(language) : null
      );
      set(language);
    },
  };
}

export default saveLanguage();
