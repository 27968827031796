import { writable } from "svelte/store";

function saveUser() {
  let user = localStorage.getItem("user");
  if (user) user = JSON.parse(user);

  const { subscribe, set } = writable(user);

  return {
    subscribe,
    set: (user) => {
      localStorage.setItem("user", user ? JSON.stringify(user) : null);
      set(user);
    },
  };
}

export default saveUser();
