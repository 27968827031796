import { derived } from "svelte/store";
import user from "./user";
import contract from "./contract";
import { getUserApps } from "../repositories/users";

const denyAll = () => false;

export default derived(
  [user, contract],
  ([$user, $contract], set) => {
    if ($user && $user.data?.id && $contract?.id) {
      getUserApps($user.data.id, $contract.id)
        .then((result) => {
          set((app) => {
            if (result && result.list.some((el) => el.id === app)) return true;
            return false;
          });
        })
        .catch(() => set(denyAll));
    }
  },
  denyAll
);
