<script>
  import { _ } from "../stores/index";
</script>

<div class="main">
  <div class="box">
    <h1 class="gl-title level-3">
      {$_("not.found.title")}
    </h1>

    <div class="text">
      <p class="mt-6 mb-2">
        {$_("not.found.text1")}
      </p>

      <p class="mt-6 mb-2">
        {$_("not.found.text2")}
      </p>
    </div>
  </div>
</div>

<style>
  .box {
    width: 774px;
    height: 353px;
    background-color: white;
    padding: 48px;
    border-radius: 8px;
    background-image: url("/src/assets/cubes-blue.svg");
    background-repeat: no-repeat;
    background-position-x: right;
  }

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    width: 70%;
  }
</style>
