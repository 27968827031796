<script>
  import { Callout, Toast, Icon, Text } from "@gl/design-system";
  import Portal from "svelte-portal";
  import { callout } from "../stores/index";

  let toast;
  let timeoutId;

  function close() {
    callout.set(null);
    if ($callout?.timeout) clearTimeout(timeoutId);
  }

  $: if (toast?.open && $callout) {
    toast.open();
  }

  $: if ($callout?.timeout) {
    timeoutId = setTimeout(close, 5000);
  }
</script>

<Portal target="body">
  {#if $callout}
    <Toast bind:this={toast} on:close={close}>
      <Callout
        status={$callout.status}
        closable
        centered
        on:close={() => toast.close()}
      >
        <Icon name={$callout.status === "success" ? "Like" : "Warning"} />
        <Text class="level-5">
          {$callout.msg}
        </Text>
      </Callout>
    </Toast>
  {/if}
</Portal>
