<script>
  import AccessManager from "@gl/access-manager";
  import { setContext, getContext } from "svelte";
  import { user, language, contract, callout, Auth } from "./stores/index";

  const config = getContext("config");

  const authClass = new Auth(user, "access-manager");
  setContext("isAuthorized", authClass);
</script>

<AccessManager
  apiurl={config.API_URL}
  user={$user}
  language={$language}
  contract={$contract}
  on:callout={(event) => callout.set(event.detail.callout)}
/>
