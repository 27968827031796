<script>
  import { _, user, contract } from "../stores/index";
  import { Icon, Flex, FlexItem } from "@gl/design-system";
  import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
</script>

<div class="main">
  <div class="box">
    <h1 class="gl-title level-3">
      {$_("forbidden.title")}
    </h1>
    <p class="mt-2">
      {$_("forbidden.subtitle")}
    </p>

    <p class="mt-6 color-grey-light mb-2">
      {$_("common.signed.as")}
    </p>

    <div class="user-box">
      <FlexItem class="mr-2">
        <Icon name="User" />
      </FlexItem>
      <FlexItem class="mr-2">
        {$user.data.firstName}
        {$user.data.lastName}
      </FlexItem>
      <div class="line mr-2"></div>

      {$user.data.email}

      <FlexItem pushEnd>
        <Flex class="align-items-center">
          <div class="line mr-2"></div>
          <Icon icon={faBuilding} />
          <b>{$contract.name} </b>
        </Flex>
      </FlexItem>
    </div>
  </div>
</div>

<style>
  .box {
    width: 774px;
    height: 353px;
    background-color: white;
    padding: 48px;
    border-radius: 8px;
    background-image: url("/src/assets/cubes-blue.svg");
    background-repeat: no-repeat;
    background-position-x: right;
  }

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-box {
    border-radius: 4px;
    background: #002735;
    width: 100%;
    padding: 6px 16px;
    color: white;
    height: 52px;
    display: flex;
    align-items: center;
  }

  .line {
    width: 1px;
    height: 24px;
    opacity: 0.5;
    background: #999;
  }
</style>
