<script>
  import { getContext } from "svelte";
  import "@gl/bundescloud-player";
  import { user, language, contract, callout, Auth } from "./stores/index";
  const config = getContext("config");

  const authClass = new Auth(user, "content-manager");
</script>

{#if $contract}
  <cm-element
    apiurl={config.API_URL}
    basepath={`/content-manager/contracts/${$contract.id}`}
    language={$language}
    contract={$contract}
    user={$user}
    isAuthorized={authClass}
    on:callout={(event) => callout.set(event.detail.callout)}
  />
{/if}
