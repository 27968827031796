import { writable } from "svelte/store";

function saveContract() {
  let contract = localStorage.getItem("contract");
  if (contract) contract = JSON.parse(contract);

  const { subscribe, set } = writable(contract);

  return {
    subscribe,
    set: (contract) => {
      localStorage.setItem(
        "contract",
        contract ? JSON.stringify(contract) : null
      );
      set(contract);
    },
  };
}

export default saveContract();
